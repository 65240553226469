import { Controller } from 'stimulus'
import flatpickr from 'flatpickr'

export default class extends Controller {
  initialize() {
    flatpickr(this.element, {})
  }

  disconnect() {}
}
