import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['link']

  initialize() {
    console.log('clicker initialized.')
  }

  click_link(event) {
    if (!this.hasLinkTarget) return

    this.linkTarget.click()
  }
}
